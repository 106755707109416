








































































import { defineComponent } from "@vue/composition-api";
import BaseDialog from "@/components/dialog/baseDialog/BaseDialog.vue";
import { i18n } from "@/locales/i18n";

export default defineComponent({
  name: "ResultSticker",
  components: {
    BaseDialog,
  },
  props: {
    title: {
      type: String,
      default: i18n.tc("VEHICLE_CATEGORY_HELPER.RESULT_STICKER.TITLE"),
    },
    contentText: String,
    infoText: String,
    categoryStickers: Array, //CategoryIcon[]
    fullScreenDialogOnXs: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const prevStep = () => {
      emit("prevStep");
    };

    const closeDialog = () => {
      emit("closeDialog");
    };
    return {
      closeDialog,
      prevStep,
    };
  },
});
