

























import { defineComponent, reactive, ref } from "@vue/composition-api";
import ResultSticker from "@/components/vehicleCategoryWizard/steps/results/resultSticker/ResultSticker.vue";
import StepMaxWeight from "@/components/vehicleCategoryWizard/steps/StepMaxWeight.vue";
import ResultHUGO from "@/components/vehicleCategoryWizard/steps/results/ResultHUGO.vue";
import { stickerD2 } from "@/core/utils/vehicle";

export default defineComponent({
  name: "Truck",
  components: {
    ResultSticker,
    StepMaxWeight,
    ResultHUGO,
  },
  setup(props, { emit }) {
    const stepHistory = reactive<string[]>(["maxWeight"]);
    const activeStep = ref("maxWeight");
    const selectionChanged = (value: string) => {
      activeStep.value = value;
      if (stepHistory[stepHistory.length - 1] !== value) {
        stepHistory.push(value);
      }
    };
    const backToCategoryStep = () => {
      emit("backToCategorySelector");
    };
    const prevStep = () => {
      if (stepHistory.length) {
        stepHistory.pop();
        activeStep.value = stepHistory[stepHistory.length - 1];
      }
    };
    const closeDialog = () => {
      emit("closeDialog");
    };
    return {
      activeStep,
      selectionChanged,
      backToCategoryStep,
      closeDialog,
      prevStep,
      stickerD2,
    };
  },
});
