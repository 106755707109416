





























import { defineComponent } from "@vue/composition-api";
import BaseDialog from "@/components/dialog/baseDialog/BaseDialog.vue";
import CardSelector from "@/components/forms/CardSelector/CardSelector.vue";
import { i18n } from "@/locales/i18n";

export default defineComponent({
  name: "StepCategorySelector",
  components: {
    BaseDialog,
    CardSelector,
  },
  setup(props, { emit }) {
    const categories: {
      icon: string;
      text: string;
      value: string;
    }[] = [
      {
        icon: "mdi-motorbike",
        text: i18n.tc(
          "VEHICLE_CATEGORY_HELPER.STEP_CATEGORY_SELECTOR.TYPE_MOTORBIKE"
        ),
        value: "motorbike",
      },
      {
        icon: "mdi-car-hatchback",
        text: i18n.tc(
          "VEHICLE_CATEGORY_HELPER.STEP_CATEGORY_SELECTOR.TYPE_CAR_PASSANGER"
        ),
        value: "carPassanger",
      },
      {
        icon: "mdi-van-passenger",
        text: i18n.tc(
          "VEHICLE_CATEGORY_HELPER.STEP_CATEGORY_SELECTOR.TYPE_VAN_PASSANGER"
        ),
        value: "vanPassanger",
      },
      {
        icon: "mdi-rv-truck",
        text: i18n.tc(
          "VEHICLE_CATEGORY_HELPER.STEP_CATEGORY_SELECTOR.TYPE_CAMPER_VAN"
        ),
        value: "camperVan",
      },
      {
        icon: "mdi-truck",
        text: i18n.tc(
          "VEHICLE_CATEGORY_HELPER.STEP_CATEGORY_SELECTOR.TYPE_TRUCK"
        ),
        value: "truck",
      },
      {
        icon: "mdi-bus-side",
        text: i18n.tc(
          "VEHICLE_CATEGORY_HELPER.STEP_CATEGORY_SELECTOR.TYPE_BUS"
        ),
        value: "bus",
      },
      {
        icon: "mdi-truck-trailer",
        text: i18n.tc(
          "VEHICLE_CATEGORY_HELPER.STEP_CATEGORY_SELECTOR.TYPE_TRAILER"
        ),
        value: "trailer",
      },
      {
        icon: "mdi-help-circle",
        text: i18n.tc(
          "VEHICLE_CATEGORY_HELPER.STEP_CATEGORY_SELECTOR.TYPE_OTHER"
        ),
        value: "otherVehicle",
      },
    ];

    const selectedCategory = (value: string) => {
      if (value) {
        emit("categorySelection", value);
      }
    };

    const closeDialog = () => {
      emit("closeDialog");
    };
    return {
      categories,
      selectedCategory,
      closeDialog,
    };
  },
});
