





























import { defineComponent } from "@vue/composition-api";
import BaseDialog from "@/components/dialog/baseDialog/BaseDialog.vue";
import CardSelector from "@/components/forms/CardSelector/CardSelector.vue";
import { i18n } from "@/locales/i18n";

export default defineComponent({
  name: "StepTrailerVehicle",
  components: {
    BaseDialog,
    CardSelector,
  },
  setup(props, { emit }) {
    const cardList: {
      icon: string;
      text: string;
      value: string;
    }[] = [
      {
        icon: "mdi-car-hatchback",
        text: i18n.tc("VEHICLE_CATEGORY_HELPER.STEP_TRAILER_VEHICLE.OPTION_D1"),
        value: "carTrailer",
      },
      {
        icon: "mdi-truck",
        text: i18n.tc(
          "VEHICLE_CATEGORY_HELPER.STEP_TRAILER_VEHICLE.OPTION_D2ORB2"
        ),
        value: "truckTrailer",
      },
      {
        icon: "mdi-close-circle",
        text: i18n.tc(
          "VEHICLE_CATEGORY_HELPER.STEP_TRAILER_VEHICLE.OPTION_NONEOFTHEM"
        ),
        value: "noTrailer",
      },
    ];

    const selection = (value: string) => {
      emit("selectionChanged", value);
    };

    const prevStep = () => {
      emit("prevStep");
    };

    const closeDialog = () => {
      emit("closeDialog");
    };
    return {
      cardList,
      selection,
      closeDialog,
      prevStep,
    };
  },
});
