


































import { defineComponent } from "@vue/composition-api";
import BaseDialog from "@/components/dialog/baseDialog/BaseDialog.vue";

export default defineComponent({
  name: "ResultNotHighwayVehicle",
  components: {
    BaseDialog,
  },
  setup(props, { emit }) {
    const prevStep = () => {
      emit("prevStep");
    };

    const closeDialog = () => {
      emit("closeDialog");
    };
    return {
      closeDialog,
      prevStep,
    };
  },
});
