var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-100 d-flex align-center"},[(_vm.activeStep === 'performance')?_c('StepMotorbikePerformance',{on:{"selectionChanged":_vm.selectionChanged,"prevStep":_vm.backToCategoryStep,"closeDialog":_vm.closeDialog}}):_vm._e(),(_vm.activeStep === 'slowMotor')?_c('ResultNotHighwayVehicle',{on:{"prevStep":_vm.prevStep,"closeDialog":_vm.closeDialog}}):_vm._e(),(_vm.activeStep === 'bigMotor')?_c('ResultSticker',{attrs:{"infoText":_vm.$t('VEHICLE_CATEGORY_HELPER.STICKER_D1M.INFO_TEXT'),"categoryStickers":[
      Object.assign({}, _vm.stickerD1M,
        {infoText: _vm.$t(
          'VEHICLE_CATEGORY_HELPER.RESULT_STICKER_MOTORBIKE_CATEGORY.D1M'
        )}),
      Object.assign({}, _vm.stickerD1,
        {infoText: _vm.$t(
          'VEHICLE_CATEGORY_HELPER.RESULT_STICKER_MOTORBIKE_CATEGORY.D1'
        )}) ]},on:{"prevStep":_vm.prevStep,"closeDialog":_vm.closeDialog}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }