





























import { defineComponent } from "@vue/composition-api";
import BaseDialog from "@/components/dialog/baseDialog/BaseDialog.vue";
import CardSelector from "@/components/forms/CardSelector/CardSelector.vue";
import { i18n } from "@/locales/i18n";

export default defineComponent({
  name: "StepCategoryJ",
  components: {
    BaseDialog,
    CardSelector,
  },
  setup(props, { emit }) {
    const cardList: {
      icon: string;
      text: string;
      value: string;
    }[] = [
      {
        icon: "mdi-check-circle",
        text: i18n.tc("VEHICLE_CATEGORY_HELPER.DIALOG_BUTTON.YES"),
        value: "M1orM1G",
      },
      {
        icon: "mdi-close-circle",
        text: i18n.tc("VEHICLE_CATEGORY_HELPER.DIALOG_BUTTON.NO"),
        value: "notM1orM1G",
      },
    ];

    const selection = (value: string) => {
      emit("selectionChanged", value);
    };

    const prevStep = () => {
      emit("prevStep");
    };

    const closeDialog = () => {
      emit("closeDialog");
    };

    return {
      cardList,
      selection,
      closeDialog,
      prevStep,
    };
  },
});
