






































import { defineComponent, reactive, ref } from "@vue/composition-api";
import StepMotorbikePerformance from "@/components/vehicleCategoryWizard/steps/StepMotorbikePerformance.vue";
import ResultSticker from "@/components/vehicleCategoryWizard/steps/results/resultSticker/ResultSticker.vue";
import ResultNotHighwayVehicle from "@/components/vehicleCategoryWizard/steps/results/ResultNotHighwayVehicle.vue";
import { stickerD1M, stickerD1 } from "@/core/utils/vehicle";

export default defineComponent({
  name: "Motorbike",
  components: {
    StepMotorbikePerformance,
    ResultNotHighwayVehicle,
    ResultSticker,
  },
  setup(props, { emit }) {
    const stepHistory = reactive<string[]>(["performance"]);
    const activeStep = ref("performance");
    const selectionChanged = (value: string) => {
      activeStep.value = value;
      if (stepHistory[stepHistory.length - 1] !== value) {
        stepHistory.push(value);
      }
    };
    const backToCategoryStep = () => {
      emit("backToCategorySelector");
    };
    const prevStep = () => {
      if (stepHistory.length) {
        stepHistory.pop();
        activeStep.value = stepHistory[stepHistory.length - 1];
      }
    };
    const closeDialog = () => {
      emit("closeDialog");
    };
    return {
      activeStep,
      selectionChanged,
      backToCategoryStep,
      closeDialog,
      prevStep,
      stickerD1M,
      stickerD1,
    };
  },
});
