



































import { defineComponent, reactive, ref } from "@vue/composition-api";
import ResultSticker from "@/components/vehicleCategoryWizard/steps/results/resultSticker/ResultSticker.vue";
import ResultHUGO from "@/components/vehicleCategoryWizard/steps/results/ResultHUGO.vue";
import StepOtherVehicle from "@/components/vehicleCategoryWizard/steps/StepOtherVehicle.vue";
import {
  otherCategoryHelperSelector,
  freeCategories,
  hugoCategories,
} from "@/core/utils/vehicle";
import { OtherCategoryHelperResult } from "@/core/dataTypes/types";

export default defineComponent({
  name: "OtherVehicle",
  components: {
    StepOtherVehicle,
    ResultSticker,
    ResultHUGO,
  },
  setup(props, { emit }) {
    const activeStep = ref("vehicleCategory");
    const activeStepData = reactive<OtherCategoryHelperResult>({
      text: "",
    });
    const selectionChanged = (value: string) => {
      if (value === "vehicleCategory") return;

      if (hugoCategories.includes(value)) {
        activeStep.value = "hugo";
      } else if (freeCategories.includes(value)) {
        activeStep.value = "free";
      } else {
        activeStep.value = "category";
      }

      const { text, stickers } = otherCategoryHelperSelector(value);

      activeStepData.text = text;
      activeStepData.stickers = stickers;
    };

    const backToCategoryStep = () => {
      emit("backToCategorySelector");
    };
    const prevStep = () => {
      activeStep.value = "vehicleCategory";
    };
    const closeDialog = () => {
      emit("closeDialog");
    };
    return {
      activeStep,
      activeStepData,
      selectionChanged,
      backToCategoryStep,
      closeDialog,
      prevStep,
    };
  },
});
