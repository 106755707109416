





























import { defineComponent } from "@vue/composition-api";
import BaseDialog from "@/components/dialog/baseDialog/BaseDialog.vue";
import CardSelector from "@/components/forms/CardSelector/CardSelector.vue";
import { svgIcons } from "@/core/utils/svgIcons";
import { i18n } from "@/locales/i18n";

export default defineComponent({
  name: "StepMaxWeight",
  components: {
    BaseDialog,
    CardSelector,
  },
  props: {
    fromPassengerCar: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const cardList: {
      icon: string;
      text: string;
      value: string;
    }[] = [
      {
        icon: svgIcons.weight1,
        text: i18n.tc(
          "VEHICLE_CATEGORY_HELPER.STEP_MAX_WEIGHT.OPTION_MAX3ANDHALF"
        ),
        value: props.fromPassengerCar ? "max3,5tPassenger" : "max3,5t",
      },
      {
        icon: svgIcons.weight3,
        text: i18n.tc(
          "VEHICLE_CATEGORY_HELPER.STEP_MAX_WEIGHT.OPTION_MORETHAN3ANDHALF"
        ),
        value: "moreThan3,5t",
      },
    ];

    const selection = (value: string) => {
      emit("selectionChanged", value);
    };

    const prevStep = () => {
      emit("prevStep");
    };

    const closeDialog = () => {
      emit("closeDialog");
    };
    return {
      cardList,
      selection,
      closeDialog,
      prevStep,
    };
  },
});
