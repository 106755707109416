






























































































































































































import { defineComponent } from "@vue/composition-api";
import BaseDialog from "@/components/dialog/baseDialog/BaseDialog.vue";
import CardSelector from "@/components/forms/CardSelector/CardSelector.vue";
import { svgIcons } from "@/core/utils/svgIcons";
import { i18n } from "@/locales/i18n";

export default defineComponent({
  name: "StepOtherVehicle",
  components: {
    BaseDialog,
    CardSelector,
  },
  setup(props, { emit }) {
    type CardListCategory = {
      text?: string;
      value: string;
      icon?: string;
      description?: string;
    };
    const cardList: { [key: string]: CardListCategory[] } = {
      slowVehicle: [
        {
          value: "LJ",
        },
      ],
      motorBike: [
        {
          value: "L1e",
        },
        {
          value: "L2e",
        },
        {
          value: "L3e",
        },
        {
          value: "L4e",
        },
        {
          value: "L5e",
        },
        {
          value: "L6e",
        },
        {
          value: "L7e",
        },
      ],
      car: [
        {
          text: "M1",
          description: i18n.tc(
            "VEHICLE_CATEGORY_HELPER.STEP_OTHER_VEHICLE.CAR_OPTION_DESCRIPTION_MAX7"
          ),
          value: "M1Max7",
        },
        {
          text: "M1",
          description: i18n.tc(
            "VEHICLE_CATEGORY_HELPER.STEP_OTHER_VEHICLE.CAR_OPTION_DESCRIPTION_MORETHAN7"
          ),
          value: "M1",
        },
        {
          text: "M1G",
          description: i18n.tc(
            "VEHICLE_CATEGORY_HELPER.STEP_OTHER_VEHICLE.CAR_OPTION_DESCRIPTION_MAX7"
          ),
          value: "M1GMax7",
        },
        {
          text: "M1G",
          description: i18n.tc(
            "VEHICLE_CATEGORY_HELPER.STEP_OTHER_VEHICLE.CAR_OPTION_DESCRIPTION_MORETHAN7"
          ),
          value: "M1G",
        },
        {
          text: "M2",
          value: "M2",
        },
        {
          text: "M3",
          value: "M3",
        },
      ],
      truck: [
        {
          value: "N1",
        },
        {
          value: "N1G",
        },
        {
          value: "N2",
        },
        {
          value: "N3",
        },
      ],
      trailer: [
        {
          value: "O1",
        },
        {
          value: "O2",
        },
        {
          value: "O3",
        },
        {
          value: "O4",
        },
      ],
      tractor: [
        {
          value: "T1",
        },
        {
          value: "T2",
        },
        {
          value: "T3",
        },
        {
          value: "T4",
        },
        {
          value: "T5",
        },
      ],
      tractorTrailer: [
        {
          value: "Ra1",
        },
        {
          value: "Ra2",
        },
        {
          value: "Ra3",
        },
        {
          value: "Ra4",
        },
        {
          value: "Rb1",
        },
        {
          value: "Rb2",
        },
        {
          value: "Rb3",
        },
        {
          value: "Rb4",
        },
      ],
      tractorMachine: [
        {
          value: "Sa1",
        },
        {
          value: "Sa2",
        },
        {
          value: "Sb1",
        },
        {
          value: "Sb2",
        },
      ],
    };

    const selection = (value: string) => {
      if (value) {
        emit("selectionChanged", value);
      }
    };

    const prevStep = () => {
      emit("prevStep");
    };

    const closeDialog = () => {
      emit("closeDialog");
    };
    return {
      cardList,
      selection,
      closeDialog,
      prevStep,
      svgIcons,
    };
  },
});
