


























































































import {
  computed,
  defineComponent,
  ref,
  SetupContext,
} from "@vue/composition-api";
import TextInput from "@/components/forms/input/TextInput.vue";
import CardSelector from "@/components/forms/CardSelector/CardSelector.vue";
import AutocompleteInput from "@/components/forms/autocomplete/AutocompleteInput.vue";
import { vehicleFacade } from "@/store/modules/vehicle/vehicle.facade";

import { validationRegExp } from "@/core/utils/validation/validationRegExp";
import { vehiclePriceCategoriesNewCar } from "@/core/utils/vehicle";
import DialogService from "@/core/services/dialog.service";
import VehicleCategoryWizardDialog from "@/components/dialog/VehicleCategoryWizardDialog.vue";

export default defineComponent({
  name: "VehicleAddForm",
  props: {
    value: Object,
  },
  components: {
    AutocompleteInput,
    TextInput,
    CardSelector,
  },
  setup(props, { emit }: SetupContext) {
    const countries = computed(vehicleFacade.countries);
    const countriesLoading = computed(vehicleFacade.countriesLoading);
    const plateNumberValidationLoading = computed(() =>
      vehicleFacade.plateNumberValidationLoading()
    );
    const innerValue = ref(props.value);
    const vehicleCategoryHelp = () => {
      DialogService.open({
        maxWidth: 560,
        customClass: "fullscreen-dialog-on-xs",
        component: VehicleCategoryWizardDialog,
      });
    };
    vehicleFacade.getCountryCodes();

    const onPlateNumberBlur = () => {
      const iv = innerValue.value as {
        plateNumber: string;
        countryCode: string;
      };
      if (iv.plateNumber && iv.countryCode) {
        emit("plateNumberBlur");
      }
    };
    const onChange = () => {
      emit("change");
    };

    return {
      innerValue,
      countries,
      countriesLoading,
      validationRegExp,
      vehiclePriceCategoriesNewCar,
      vehicleCategoryHelp,
      onPlateNumberBlur,
      onChange,
      plateNumberValidationLoading,
    };
  },
});
