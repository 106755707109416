




















































import { defineComponent, ref } from "@vue/composition-api";
import StepCategorySelector from "@/components/vehicleCategoryWizard/steps/StepCategorySelector.vue";
import { dialogCloseEmitter } from "@/core/utils/dialog/dialogEmitter";
import PassangerCar from "@/components/vehicleCategoryWizard/PassangerCar.vue";
import Motorbike from "@/components/vehicleCategoryWizard/Motorbike.vue";
import CamperVan from "@/components/vehicleCategoryWizard/CamperVan.vue";
import Truck from "@/components/vehicleCategoryWizard/Truck.vue";
import Trailer from "@/components/vehicleCategoryWizard/Trailer.vue";
import Bus from "@/components/vehicleCategoryWizard/Bus.vue";
import OtherVehicle from "@/components/vehicleCategoryWizard/OtherVehicle.vue";

export default defineComponent({
  name: "VehicleCategoryWizardDialog",
  components: {
    StepCategorySelector,
    PassangerCar,
    Motorbike,
    CamperVan,
    Trailer,
    Truck,
    Bus,
    OtherVehicle,
  },
  setup(props, ctx) {
    const activeStep = ref<string>("categorySelector");

    const categorySelected = (value: string) => {
      activeStep.value = value;
    };

    const close = () => {
      dialogCloseEmitter(ctx);
    };

    const backToCategorySelector = () => {
      activeStep.value = "categorySelector";
    };
    return {
      close,
      activeStep,
      categorySelected,
      backToCategorySelector,
    };
  },
});
