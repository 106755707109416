














































import { defineComponent, reactive, ref } from "@vue/composition-api";
import ResultSticker from "@/components/vehicleCategoryWizard/steps/results/resultSticker/ResultSticker.vue";
import StepTrailerVehicle from "@/components/vehicleCategoryWizard/steps/StepTrailerVehicle.vue";
import StepTrailerMaxWeight from "@/components/vehicleCategoryWizard/steps/StepTrailerMaxWeight.vue";
import ResultHUGO from "@/components/vehicleCategoryWizard/steps/results/ResultHUGO.vue";
import ResultFreeTicket from "@/components/vehicleCategoryWizard/steps/results/ResultFreeTicket.vue";
import { stickerU } from "@/core/utils/vehicle";

export default defineComponent({
  name: "Trailer",
  components: {
    StepTrailerVehicle,
    ResultSticker,
    ResultHUGO,
    ResultFreeTicket,
    StepTrailerMaxWeight,
  },
  setup(props, { emit }) {
    const stepHistory = reactive<string[]>(["trailerVehicle"]);
    const activeStep = ref("trailerVehicle");
    const selectionChanged = (value: string) => {
      activeStep.value = value;
      if (stepHistory[stepHistory.length - 1] !== value) {
        stepHistory.push(value);
      }
    };
    const backToCategoryStep = () => {
      emit("backToCategorySelector");
    };
    const prevStep = () => {
      if (stepHistory.length) {
        stepHistory.pop();
        activeStep.value = stepHistory[stepHistory.length - 1];
      }
    };
    const closeDialog = () => {
      emit("closeDialog");
    };
    return {
      activeStep,
      selectionChanged,
      backToCategoryStep,
      closeDialog,
      prevStep,
      stickerU,
    };
  },
});
