export const svgIcons: { [key: string]: string } = {
  weight1:
    "M14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7C8 7.73 8.19 8.41 8.54 9H6C5.05 9 4.25 9.67 4.05 10.56C2.04 18.57 2 18.78 2 19C2 19.5304 2.21071 20.0391 2.58579 20.4142C2.96086 20.7893 3.46957 21 4 21H20C20.5304 21 21.0391 20.7893 21.4142 20.4142C21.7893 20.0391 22 19.5304 22 19C22 18.78 21.96 18.57 19.95 10.56C19.75 9.67 18.95 9 18 9H15.46C15.81 8.41 16 7.73 16 7C16 5.93913 15.5786 4.92172 14.8284 4.17157ZM10.5858 5.58579C10.9609 5.21071 11.4696 5 12 5C12.5304 5 13.0391 5.21071 13.4142 5.58579C13.7893 5.96086 14 6.46957 14 7C14 7.53043 13.7893 8.03914 13.4142 8.41421C13.0391 8.78929 12.5304 9 12 9C11.4696 9 10.9609 8.78929 10.5858 8.41421C10.2107 8.03914 10 7.53043 10 7C10 6.46957 10.2107 5.96086 10.5858 5.58579ZM9.5 14V16H14.5V14H9.5Z",
  weight2:
    "M14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7C8 7.73 8.19 8.41 8.54 9H6C5.05 9 4.25 9.67 4.05 10.56C2.04 18.57 2 18.78 2 19C2 19.5304 2.21071 20.0391 2.58579 20.4142C2.96086 20.7893 3.46957 21 4 21H20C20.5304 21 21.0391 20.7893 21.4142 20.4142C21.7893 20.0391 22 19.5304 22 19C22 18.78 21.96 18.57 19.95 10.56C19.75 9.67 18.95 9 18 9H15.46C15.81 8.41 16 7.73 16 7C16 5.93913 15.5786 4.92172 14.8284 4.17157ZM10.5858 5.58579C10.9609 5.21071 11.4696 5 12 5C12.5304 5 13.0391 5.21071 13.4142 5.58579C13.7893 5.96086 14 6.46957 14 7C14 7.53043 13.7893 8.03914 13.4142 8.41421C13.0391 8.78929 12.5304 9 12 9C11.4696 9 10.9609 8.78929 10.5858 8.41421C10.2107 8.03914 10 7.53043 10 7C10 6.46957 10.2107 5.96086 10.5858 5.58579ZM14.4 13V14.5H9.40002V13H14.4ZM14.4 16V17.5H9.40002V16H14.4Z",
  weight3:
    "M14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7C8 7.73 8.19 8.41 8.54 9H6C5.05 9 4.25 9.67 4.05 10.56C2.04 18.57 2 18.78 2 19C2 19.5304 2.21071 20.0391 2.58579 20.4142C2.96086 20.7893 3.46957 21 4 21H20C20.5304 21 21.0391 20.7893 21.4142 20.4142C21.7893 20.0391 22 19.5304 22 19C22 18.78 21.96 18.57 19.95 10.56C19.75 9.67 18.95 9 18 9H15.46C15.81 8.41 16 7.73 16 7C16 5.93913 15.5786 4.92172 14.8284 4.17157ZM10.5858 5.58579C10.9609 5.21071 11.4696 5 12 5C12.5304 5 13.0391 5.21071 13.4142 5.58579C13.7893 5.96086 14 6.46957 14 7C14 7.53043 13.7893 8.03914 13.4142 8.41421C13.0391 8.78929 12.5304 9 12 9C11.4696 9 10.9609 8.78929 10.5858 8.41421C10.2107 8.03914 10 7.53043 10 7C10 6.46957 10.2107 5.96086 10.5858 5.58579ZM14.4 11.5V13H9.40002V11.5H14.4ZM14.4 16V14.5H9.40002V16H14.4ZM14.4 19V17.5H9.40002V19H14.4Z",
  tractorTrailer:
    "M15.3 10.2h4.767v3.467h3.466V15.4H17.9c0 .72-.269 1.326-.789 1.846A2.468 2.468 0 0115.3 18c-.72 0-1.326-.243-1.837-.754A2.493 2.493 0 0112.7 15.4H7.933c0 .72-.26 1.326-.771 1.846-.529.511-1.127.754-1.829.754s-1.3-.243-1.828-.754c-.512-.52-.772-1.127-.772-1.846H1v-5.2l10.4.052 3.9-.052zm.901 6.127c-.26.26-.563.373-.901.373-.364 0-.667-.113-.927-.373A1.242 1.242 0 0114 15.4c0-.338.113-.641.373-.901s.563-.399.927-.399c.338 0 .641.139.901.399s.399.563.399.901c0 .364-.139.667-.399.927zm-9.932 0c-.242.26-.554.373-.936.373-.381 0-.693-.113-.936-.373a1.303 1.303 0 01-.364-.927c0-.338.122-.641.364-.901a1.23 1.23 0 01.936-.399c.382 0 .694.139.936.399.243.26.364.563.364.901 0 .364-.121.667-.364.927z",
  tractorMachine:
    "M15.3 16.7c.338 0 .641-.113.901-.373s.399-.563.399-.927c0-.338-.139-.641-.399-.901s-.563-.399-.901-.399c-.364 0-.667.139-.927.399S14 15.062 14 15.4c0 .364.113.667.373.927s.563.373.927.373zm-9.967 0c.382 0 .694-.113.936-.373.243-.26.364-.563.364-.927 0-.338-.121-.641-.364-.901a1.23 1.23 0 00-.936-.399 1.23 1.23 0 00-.936.399c-.242.26-.364.563-.364.901 0 .364.122.667.364.927.243.26.555.373.936.373zm9.967-6.5h4.767v3.467h3.466V15.4H17.9c0 .72-.269 1.326-.789 1.846A2.469 2.469 0 0115.3 18c-.72 0-1.326-.243-1.837-.754A2.493 2.493 0 0112.7 15.4H7.933c0 .72-.26 1.326-.771 1.846-.529.511-1.127.754-1.829.754s-1.3-.243-1.828-.754c-.512-.52-.772-1.127-.772-1.846H1v-3.467h7.098L2.733 7.695V10.2H1V5h.867l9.533 5.252 3.9-.052z",
};
