









































import { defineComponent, reactive, ref } from "@vue/composition-api";
import ResultSticker from "@/components/vehicleCategoryWizard/steps/results/resultSticker/ResultSticker.vue";
import StepMaxWeight from "@/components/vehicleCategoryWizard/steps/StepMaxWeight.vue";
import StepMaxPerson from "@/components/vehicleCategoryWizard/steps/StepMaxPerson.vue";
import { stickerB2, stickerD2, stickerD1 } from "@/core/utils/vehicle";

export default defineComponent({
  name: "Bus",
  components: {
    StepMaxWeight,
    StepMaxPerson,
    ResultSticker,
  },
  setup(props, { emit }) {
    const stepHistory = reactive<string[]>(["busWeight"]);
    const activeStep = ref("busWeight");
    const selectionChanged = (value: string) => {
      activeStep.value = value;
      if (stepHistory[stepHistory.length - 1] !== value) {
        stepHistory.push(value);
      }
    };
    const backToCategoryStep = () => {
      emit("backToCategorySelector");
    };
    const prevStep = () => {
      if (stepHistory.length) {
        stepHistory.pop();
        activeStep.value = stepHistory[stepHistory.length - 1];
      }
    };
    const closeDialog = () => {
      emit("closeDialog");
    };
    return {
      activeStep,
      selectionChanged,
      backToCategoryStep,
      closeDialog,
      prevStep,
      stickerB2,
      stickerD2,
      stickerD1,
    };
  },
});
